import marquee from 'vanilla-marquee/dist/vanilla-marquee.js';

if (document.querySelector('.clients__marquee')) {
  const marqueeArray = [];

  const marqueeArr = document.querySelectorAll('.clients__marquee');
  marqueeArr.forEach((marqueeEl, i) => {
    /* const itemsArr = marqueeEl.querySelectorAll('.clients__logo');
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });
    itemsArr.forEach((itemEl) => {
      const clone = itemEl.cloneNode(true);
      marqueeEl.appendChild(clone);
    });*/

    const container = 1440;

    const finalItemsArr = marqueeEl.querySelectorAll('.clients__logo');

    if(finalItemsArr.length) {
      let duration = 5000;

      const totalWidth =
        finalItemsArr.length * (finalItemsArr[0].clientWidth + 12);
      let baseSpeed = 4;
  
      let scalingFactor = window.innerWidth < 768 ? 1.1 : 1.5;
  
      if (window.innerWidth >= 1921) {
        scalingFactor = 2;
      }
  
      baseSpeed *= scalingFactor;
  
      duration = (totalWidth / window.innerWidth) * baseSpeed;
  
      let direction = 'up';
      let pauseOnHover = true;
  
      if (window.innerWidth <= 1024) {
        pauseOnHover = false;
      }
  
      direction = 'left';
      if (i % 2) {
        direction = 'right';
      }
  
      const mq = new marquee(marqueeEl, {
        delayBeforeStart: 0,
        direction: direction,
        pauseOnHover: pauseOnHover,
        duplicated: true,
        recalcResize: window.innerWidth >= 768,
        startVisible: true,
        gap: 12,
        duration: duration * 2500,
      });
  
      marqueeArray.push(mq);
    }


  });

}
